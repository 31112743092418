import React from "react";

export default function MasterPlan() {
  return (
    <div>
      <div className="masterplan">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Master Plan</h1>
              <img
                src="./images/SNN RAJ VIVIENTE-1/masterplan/master_plan.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
