import React from "react";

export default function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="disclimer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <hr />
                <p>
                  Disclaimer The Project is being Developed by SNN Abobe LLP.
                  The Project is registered as “RAJ VIVIENTE"with RERA No:
                  PRM/KA/RERA/1251/310/PR/011022/005290, available at website:
                  <a href="http://rera.karnataka.gov.in">
                    http://rera.karnataka.gov.in
                  </a>
                  . The information is presented as general information and no
                  warranty is expressly or impliedly given that the completed
                  development will comply in any degree with such artist’s
                  impression or anticipated appearance.Recipients are advised to
                  apprise themselves of the necessary and relevant information
                  of the project(s)/offer(s) prior to making any purchase
                  decisions. The Sale is subject to terms of Application Form
                  and Agreement for Sale. All specifications of the unit shall
                  be as per the final agreement between the Parties. *The prices
                  mentioned are an indicative Agreement Value. Stamp Duty
                  Registration, GST and Other Charges over and above the
                  Agreement Value. The amenities mentioned here are a part of
                  RAJ VIVIENTE and proposed future development. This is an
                  initiative by Smita an Individual Channel Partner RERA Regn
                  No. PRM/KA/RERA/1251/310/AG/220521/002906 to provide
                  information about RAJ VIVIENTE is not to be construed as an
                  official site of SNN RAJ CORP. Distance mentioned is as per
                  google map. Drive time refers to the time taken to travel by a
                  car based on normal traffic conditions during non-peak hours
                  as per Google maps may vary according to the current traffic
                  conditions. Information provided is based on Online sources,
                  the developer does not provide any guarantee on the same.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
