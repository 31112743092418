import React from "react";

export default function MockupVilla() {
  return (
    <div className="mockupvilla">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>Spacious Villa</h1>

            <div class="video-container">
              <iframe
                width="942"
                height="530"
                src="https://www.youtube.com/embed/jMz0x2-iUXc"
                title="snn raj viviente model villa"
                frameborder="0"
                allow=""
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
