import React from "react";

export default function Herosection() {
  return (
    <div>
      <div
        class="modal fade "
        id="pageload_model"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              {/* <div className="good_news">Good news</div> */}
              {/* <img
                src="./images/launch_rocket.png"
                alt=""
                className="img-fluid"
              /> */}
              <div className="phase_two">
                <h1>
                  Hurry up! <br />
                </h1>
                <h3> Limited inventory available now!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="herosection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 align-self-center text-center">
              <p>
                <b> Raj Viviente </b> is an ongoing residential project of
                <b> 3891 SQFT</b> luxury villas with luxurious double height
                living area located off Bannerghatta Road, Bengaluru.
              </p>
              <h1>Bookings Open.. </h1>
              {/* <button
                type="button"
                class="btn mt-2"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Enquire Now
              </button> */}
              <a class="btn mt-2 enq-btn" href="#contact">
                Enquire Now
              </a>
              <div
                class="modal fade text-left"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered  modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Contact form
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form
                        name="contact"
                        method="post"
                        data-netlify="true"
                        onSubmit="submit"
                        data-netlify-honeypot="bot-field"
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <div class="row">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              name="name"
                            />
                          </div>
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              name="phonenumber"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              name="email"
                            />
                          </div>
                          <div class="col">
                            <fieldset>
                              <div class="some-class">
                                <label>Need Brochure</label>{" "}
                                <br class="mobile-break"></br>
                                <input
                                  type="radio"
                                  class=" ml-0 ml-md-3 mr-1"
                                  name="brochure"
                                  value="Yes"
                                />
                                <label for="y">Yes</label>
                                <input
                                  type="radio"
                                  class="radio ml-3 mr-1"
                                  name="brochure"
                                  value="No"
                                />
                                <label for="no">No</label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              type="text"
                              required
                              class="form-control"
                              placeholder="Message"
                              name="message"
                            />
                          </div>
                        </div>
                        {/* 
                        <div className="row">
                          <div className="col-12">
                            <div
                              style={{ fontSize: 12 }}
                              className="text-center"
                            >
                              *Phase - 1 Sold Out,{" "}
                              <b>Hurry up for Phase - 2 (NEW LAUNCH)</b>
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-12 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary mt-4"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 text-center">
              <img
                src="./images/slider_images/0001.jpg"
                className="img-fluid coming_soon mt-4 mt-md-0"
                alt=""
              />{" "}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
