import React from "react";

export default function Navbar() {
  return (
    <div>
      <div className="homepage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">
              <img
                src="./images/snnrajlogo_2.jpg"
                className="img-fluid snn-logo"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
