import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Component/Footer";
import Herosection from "./Component/Herosection";
import Navbar from "./Component/Navbar";
import Slider from "./Component/Slider";
import MasterPlan from "./Component/MasterPlan";
import VillaLayout from "./Component/VillaLayout";
import Location from "./Component/Location";
import Contact from "./Component/Contact";
import MockupVilla from "./Component/MockupVilla";

function App() {
  return (
    <div>
      <Navbar />
      <Herosection />
      <Slider />
      <MasterPlan />
      <VillaLayout />
      <Location />
      <Contact />
      <MockupVilla />
      <Footer />
    </div>
  );
}

export default App;
