import React from "react";

export default function VillaLayout() {
  return (
    <div>
      <div className="villalayout">
        <div className="container">
          <hr />
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <div className="heading_title">Unit Floor Plan</div>
                <div className="floor_title">
                  Super Built-up Area (3891 SQFT)
                </div>
                <div className="flat_facing">East Facing</div>
              </div>
              <ul
                class="nav nav-pills mb-3 justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#east_facing_gf"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    GF plan
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#east_facing_ff"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    FF plan
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#east_facing_sf"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    SF plan
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#east_facing_tf"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    TF plan
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="east_facing_gf"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/East_Facing_GF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="east_facing_ff"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    {/*<div className="col-md-3 align-self-center">
                       <div className="floor_title">RERA Carpet Area</div>
                      <div className="floor_desc">
                        72.21 Sqm
                        <span> (778 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">EBVT Area</div>
                      <div className="floor_desc">
                        13.6 Sqm
                        <span> (150 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">
                        Proportionate Common Area
                      </div>
                      <div className="floor_desc">
                        30.94 Sqm
                        <span> (333 SQ.FT.)</span>
                      </div>
                      <div className="floor_title">Super Built-up Area</div>
                      <div className="floor_desc">3891 SQFT</div>
                    </div> */}
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/East_Facing_FF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="east_facing_sf"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row">
                    {/* <div className="col-md-3 align-self-center">
                      <div className="floor_title">RERA Carpet Area</div>
                      <div className="floor_desc">
                        72.21 Sqm
                        <span> (778 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">EBVT Area</div>
                      <div className="floor_desc">
                        10.26 Sqm
                        <span> (111 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">
                        Proportionate Common Area
                      </div>
                      <div className="floor_desc">
                        29.54 Sqm
                        <span> (318 SQ.FT.)</span>
                      </div> 
                      <div className="floor_title">Super Built-up Area</div>
                      <div className="floor_desc">3891 SQFT</div>
                    </div>*/}
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/East_Facing_SF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="east_facing_tf"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div className="row">
                    {/*   <div className="col-md-3 align-self-center">
                     <div className="floor_title">RERA Carpet Area</div>
                      <div className="floor_desc">
                        98.66 Sqm
                        <span> (1062 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">EBVT Area</div>
                      <div className="floor_desc">
                        14.89 Sqm
                        <span> (161 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">
                        Proportionate Common Area
                      </div>
                      <div className="floor_desc">
                        39.95 Sqm
                        <span> (430 SQ.FT.)</span>
                      </div> 
                      <div className="floor_title">Super Built-up Area</div>
                      <div className="floor_desc">3891 SQFT</div>
                    </div>*/}
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/East_Facing_TF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <div className="heading_title">Unit Floor Plan</div>
                <div className="floor_title">
                  Super Built-up Area (3891 SQFT)
                </div>
                <div className="flat_facing">West Facing</div>
              </div>
              <ul
                class="nav nav-pills mb-3 justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#west_facing_gf"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    GF plan
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#west_facing_ff"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    FF plan
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#west_facing_sf"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    SF plan
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#west_facing_tf"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    TF plan
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="west_facing_gf"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    {/*   <div className="col-md-3 align-self-center">
                      <div className="floor_title">RERA Carpet Area</div>
                      <div className="floor_desc">
                        80.71 Sqm
                        <span> (869 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">EBVT Area</div>
                      <div className="floor_desc">
                        7.85 Sqm
                        <span> (85 SQ.FT.)</span>
                      </div>
                      <div className="floor_title">
                        Proportionate Common Area
                      </div>
                      <div className="floor_desc">
                        30.75 Sqm
                        <span> (331 SQ.FT.)</span>
                      </div> 
                    </div>*/}
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/West_Facing_GF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="west_facing_ff"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    {/*  <div className="col-md-3 align-self-center">
                     <div className="floor_title">RERA Carpet Area</div>
                      <div className="floor_desc">
                        72.21 Sqm
                        <span> (778 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">EBVT Area</div>
                      <div className="floor_desc">
                        13.6 Sqm
                        <span> (150 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">
                        Proportionate Common Area
                      </div>
                      <div className="floor_desc">
                        30.94 Sqm
                        <span> (333 SQ.FT.)</span>
                      </div> 
                      <div className="floor_title">Super Built-up Area</div>
                      <div className="floor_desc">3891 SQFT</div>
                    </div>*/}
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/West_Facing_FF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="west_facing_sf"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row">
                    {/* <div className="col-md-3 align-self-center">
                       <div className="floor_title">RERA Carpet Area</div>
                      <div className="floor_desc">
                        72.21 Sqm
                        <span> (778 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">EBVT Area</div>
                      <div className="floor_desc">
                        10.26 Sqm
                        <span> (111 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">
                        Proportionate Common Area
                      </div>
                      <div className="floor_desc">
                        29.54 Sqm
                        <span> (318 SQ.FT.)</span>
                      </div> 
                      <div className="floor_title">Super Built-up Area</div>
                      <div className="floor_desc">3891 SQFT</div>
                    </div>*/}
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/West_Facing_SF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="west_facing_tf"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div className="row">
                    {/* <div className="col-md-3 align-self-center">
                      <div className="floor_title">RERA Carpet Area</div>
                      <div className="floor_desc">
                        98.66 Sqm
                        <span> (1062 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">EBVT Area</div>
                      <div className="floor_desc">
                        14.89 Sqm
                        <span> (161 SQ.FT.)</span>
                      </div>

                      <div className="floor_title">
                        Proportionate Common Area
                      </div>
                      <div className="floor_desc">
                        39.95 Sqm
                        <span> (430 SQ.FT.)</span>
                      </div> 
                      <div className="floor_title">Super Built-up Area</div>
                      <div className="floor_desc">3891 SQFT</div>
                    </div>*/}
                    <div className="col-md-12 text-center">
                      <img
                        src="./images/layout/West_Facing_TF_plan.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
